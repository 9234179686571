import Box from "@mui/material/Box";

const Games = () => {
	return (
		<Box>			
			<h2>Games</h2>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Adipiscing diam donec adipiscing tristique risus nec feugiat in fermentum. Id diam vel quam elementum pulvinar etiam. Vitae nunc sed velit dignissim sodales ut eu sem. Et molestie ac feugiat sed lectus. Vitae nunc sed velit dignissim sodales ut. Quis risus sed vulputate odio ut enim. Nibh tortor id aliquet lectus proin. Risus sed vulputate odio ut enim blandit volutpat maecenas. Cursus in hac habitasse platea dictumst quisque sagittis purus sit. Mauris in aliquam sem fringilla ut. Tristique senectus et netus et malesuada. Condimentum mattis pellentesque id nibh tortor id aliquet lectus proin. Sit amet venenatis urna cursus. Risus ultricies tristique nulla aliquet enim. Ac auctor augue mauris augue. Enim nulla aliquet porttitor lacus. Justo donec enim diam vulputate ut.</p>

			<p>Feugiat in ante metus dictum at tempor commodo. Nisl tincidunt eget nullam non. Lorem donec massa sapien faucibus et molestie ac feugiat. Vulputate odio ut enim blandit. Sagittis orci a scelerisque purus. Ornare arcu odio ut sem nulla pharetra diam. Ac auctor augue mauris augue. Ante metus dictum at tempor commodo ullamcorper. Quisque non tellus orci ac. Turpis nunc eget lorem dolor sed. Velit ut tortor pretium viverra. Risus pretium quam vulputate dignissim. Ridiculus mus mauris vitae ultricies leo integer malesuada. Mauris vitae ultricies leo integer malesuada nunc. Quis eleifend quam adipiscing vitae proin sagittis. Volutpat sed cras ornare arcu dui vivamus arcu felis bibendum.</p>

			<p>Porttitor massa id neque aliquam vestibulum morbi blandit. In dictum non consectetur a erat nam at lectus urna. Cursus vitae congue mauris rhoncus. Sed sed risus pretium quam vulputate dignissim suspendisse in est. Sed augue lacus viverra vitae congue eu consequat ac felis. Ipsum a arcu cursus vitae congue mauris. Enim eu turpis egestas pretium aenean pharetra. Habitant morbi tristique senectus et netus et. Etiam tempor orci eu lobortis elementum nibh tellus molestie. Tristique nulla aliquet enim tortor. Massa ultricies mi quis hendrerit. Sit amet aliquam id diam. Convallis a cras semper auctor neque.</p>

			<p>Ut etiam sit amet nisl purus in mollis. In iaculis nunc sed augue lacus viverra vitae congue. Sed arcu non odio euismod lacinia at quis. Ac placerat vestibulum lectus mauris ultrices eros. Augue interdum velit euismod in pellentesque massa placerat duis ultricies. Egestas purus viverra accumsan in nisl nisi. Varius quam quisque id diam vel quam. Porta non pulvinar neque laoreet suspendisse interdum consectetur libero. Vitae semper quis lectus nulla at volutpat diam ut venenatis. Nunc mi ipsum faucibus vitae aliquet. In aliquam sem fringilla ut morbi tincidunt augue interdum. Dapibus ultrices in iaculis nunc sed augue lacus. Nec ullamcorper sit amet risus nullam eget.</p>

			<p>At erat pellentesque adipiscing commodo elit at. Lectus proin nibh nisl condimentum. Sem fringilla ut morbi tincidunt augue interdum. Eu augue ut lectus arcu bibendum. Tempor id eu nisl nunc mi ipsum faucibus vitae. Eu nisl nunc mi ipsum faucibus vitae aliquet. Feugiat vivamus at augue eget arcu dictum varius. In cursus turpis massa tincidunt dui ut ornare lectus. Ornare suspendisse sed nisi lacus sed viverra tellus in. Vel orci porta non pulvinar neque laoreet suspendisse. Consectetur adipiscing elit pellentesque habitant morbi tristique. Neque viverra justo nec ultrices dui sapien. Velit euismod in pellentesque massa placerat duis ultricies. Tincidunt augue interdum velit euismod in pellentesque. Morbi tincidunt ornare massa eget egestas purus viverra accumsan in. Mollis nunc sed id semper. Amet purus gravida quis blandit turpis cursus.</p>

			<p>Sed risus ultricies tristique nulla aliquet enim tortor. Interdum velit laoreet id donec ultrices. Ipsum faucibus vitae aliquet nec ullamcorper. Lobortis elementum nibh tellus molestie nunc non blandit. Metus dictum at tempor commodo ullamcorper. Euismod lacinia at quis risus sed vulputate odio ut enim. Eget gravida cum sociis natoque penatibus et magnis. Risus in hendrerit gravida rutrum quisque. Aenean vel elit scelerisque mauris pellentesque pulvinar. Lacus sed turpis tincidunt id aliquet risus feugiat. Augue mauris augue neque gravida. Enim nec dui nunc mattis enim ut. Pretium nibh ipsum consequat nisl vel. Nibh venenatis cras sed felis eget velit aliquet sagittis. Vitae et leo duis ut diam quam nulla. Malesuada pellentesque elit eget gravida. Volutpat lacus laoreet non curabitur gravida arcu ac.</p>

			<p>Pharetra diam sit amet nisl suscipit adipiscing bibendum est. Purus viverra accumsan in nisl nisi scelerisque. Adipiscing vitae proin sagittis nisl. Magna eget est lorem ipsum dolor sit amet consectetur. Nisi est sit amet facilisis magna etiam tempor orci eu. Sed nisi lacus sed viverra tellus in hac habitasse platea. Quis blandit turpis cursus in. Aenean pharetra magna ac placerat vestibulum lectus. Sit amet purus gravida quis blandit turpis. Nulla at volutpat diam ut venenatis tellus in metus vulputate. Pretium lectus quam id leo. Placerat vestibulum lectus mauris ultrices eros. Lectus sit amet est placerat in. Enim facilisis gravida neque convallis a cras. Enim lobortis scelerisque fermentum dui faucibus in.</p>

			<p>Turpis massa sed elementum tempus egestas. Dui faucibus in ornare quam viverra orci sagittis eu. In hendrerit gravida rutrum quisque non tellus orci. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat. Tellus in metus vulputate eu scelerisque. Elementum curabitur vitae nunc sed velit dignissim. Cras tincidunt lobortis feugiat vivamus at augue eget. Bibendum at varius vel pharetra vel. In pellentesque massa placerat duis ultricies lacus sed turpis. Fringilla ut morbi tincidunt augue interdum velit euismod. Neque egestas congue quisque egestas diam in arcu. Tellus integer feugiat scelerisque varius. Ipsum consequat nisl vel pretium lectus. Nunc eget lorem dolor sed viverra ipsum nunc. Massa vitae tortor condimentum lacinia quis. Id donec ultrices tincidunt arcu non sodales. Dictumst quisque sagittis purus sit amet volutpat. Nunc sed velit dignissim sodales ut eu.</p>

			<p>Augue lacus viverra vitae congue eu consequat ac felis. Fames ac turpis egestas sed. Orci a scelerisque purus semper eget duis at tellus. Ornare aenean euismod elementum nisi quis. Fames ac turpis egestas sed tempus urna et pharetra pharetra. Scelerisque eleifend donec pretium vulputate. Mi eget mauris pharetra et ultrices neque ornare aenean euismod. Lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci. Diam maecenas sed enim ut sem. Odio ut sem nulla pharetra diam sit amet. Tellus at urna condimentum mattis pellentesque id nibh. Leo vel orci porta non pulvinar neque. In nibh mauris cursus mattis molestie.</p>

			<p>Volutpat diam ut venenatis tellus in metus vulputate eu scelerisque. Mi tempus imperdiet nulla malesuada pellentesque elit eget gravida. Augue neque gravida in fermentum. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Eget egestas purus viverra accumsan in nisl. Nisi vitae suscipit tellus mauris. Eget mauris pharetra et ultrices. Sed viverra ipsum nunc aliquet bibendum. Donec et odio pellentesque diam volutpat commodo sed. Sodales ut etiam sit amet nisl purus in mollis nunc. Donec enim diam vulputate ut pharetra sit amet aliquam. Semper auctor neque vitae tempus quam pellentesque nec. Nunc mattis enim ut tellus.</p>
		</Box>	
	);
}

export default Games;
