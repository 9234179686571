import Box from "@mui/material/Box";

const InteractiveWorksheets = () => {
	return (
		<Box>			
			<h2>Interactive Worksheets</h2>
		</Box>	
	);
}

export default InteractiveWorksheets;
