import Box from "@mui/material/Box";

const Library = () => {
	return (
		<Box>			
			<h2>Library</h2>
		</Box>	
	);
}

export default Library;
