import Box from "@mui/material/Box";

const Videos = () => {
	return (
		<Box>			
			<h2>Videos</h2>
		</Box>	
	);
}

export default Videos;
