import React from "react"
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
/**
 * Icons
 */
import LinkIcon from '@mui/icons-material/Link';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import TerminalTwoToneIcon from '@mui/icons-material/TerminalTwoTone';
import SportsEsportsTwoToneIcon from '@mui/icons-material/SportsEsportsTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import OndemandVideoTwoToneIcon from '@mui/icons-material/OndemandVideoTwoTone';
import CottageTwoToneIcon from '@mui/icons-material/CottageTwoTone';
/**
 * AT2 Specific
 */
import { openDrawer, closeDrawer } from "../slices/mainDrawerSlice";
import { useAppSelector, useAppDispatch } from "../hooks/hooks"

const MainDrawer = () => {
	const isVisible = useAppSelector((state) => state.drawer.isVisible);
	const dispatch = useAppDispatch();

	const toggleDrawer = () => (event: any) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}

		dispatch(isVisible ? closeDrawer() : openDrawer());
	};

	const list = () => (
		<Box
			sx={{ width: 300 }}
			role="presentation"
			onClick={toggleDrawer()}
			onKeyDown={toggleDrawer()}
		>
			<List>
				<Link to={"/"} className="drawerLink">
					<ListItem button key={"Home"}>
						<ListItemIcon>
							<CottageTwoToneIcon />
						</ListItemIcon>
						<ListItemText primary={"Home"} />
					</ListItem>
				</Link>			
				<Link to={"games"} className="drawerLink">
					<ListItem button key={"Games"}>
						<ListItemIcon>
							<SportsEsportsTwoToneIcon />
						</ListItemIcon>
						<ListItemText primary={"Games"} />
					</ListItem>
				</Link>
				<Link to={"iw"} className="drawerLink">
					<ListItem button key={"IW"}>
						<ListItemIcon>
							<ListAltTwoToneIcon />
						</ListItemIcon>
						<ListItemText primary={"Interactive Worksheets"} />
					</ListItem>
				</Link>
				<Link to={"videos"} className="drawerLink">
					<ListItem button key={"Videos"}>
						<ListItemIcon>
							<OndemandVideoTwoToneIcon />
						</ListItemIcon>
						<ListItemText primary={"Videos"} />
					</ListItem>
				</Link>
			</List>
			<Divider />
			<List>
				<Link to={"learning"} className="drawerLink">
					<ListItem button key={"Learning"}>
						<ListItemIcon>
							<SchoolTwoToneIcon />
						</ListItemIcon>
						<ListItemText primary={"Learning"} />
					</ListItem>
				</Link>
				<Link to={"library"} className="drawerLink">
					<ListItem button key={"Library"}>
						<ListItemIcon>
							<LibraryBooksTwoToneIcon />
						</ListItemIcon>
						<ListItemText primary={"Library"} />
					</ListItem>
				</Link>
			</List>
			<Divider />
			<List>
				<Link to={"cli"} className="drawerLink">
					<ListItem button key={"CLI"}>
						<ListItemIcon>
							<TerminalTwoToneIcon />
						</ListItemIcon>
						<ListItemText primary={"Commands"} />
					</ListItem>
				</Link>
				<a href="https://schoolzone.com" target="_blank"  rel="noreferrer" className="drawerLink">
					<ListItem button key={"School Zone"}>
						<ListItemIcon>
							<LinkIcon />
						</ListItemIcon>
						<ListItemText primary={"School Zone"} />
					</ListItem>
				</a>
			</List>
		</Box>
	);

	return (
		<div>
			<React.Fragment key={"drawerLeft"}>
				<Drawer
					anchor="left"
					open={isVisible}
					onClose={toggleDrawer()}
				>
					{list()}
				</Drawer>
			</React.Fragment>
		</div>
	);
}

export default MainDrawer;
