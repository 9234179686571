import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
/**
 * Anywhere Teacher modules
 */
import Footer from "../components/Footer";
import Header from "../components/Header";
import MainDrawer from "../containers/MainDrawer";
import React from "react";
// import { useAppDispatch } from "./hooks/hooks"

const Root = () => {
	// const dispatch = useAppDispatch();

	const fullWidthHeaderStyle = {
		paddingLeft: 0,
		paddingRight: 0,
		maxWidth: "100%"
	}

	return (
		<React.Fragment>
			<Container style={fullWidthHeaderStyle}>
				<Header />
			</Container>
			<Container>
				<Box>
					<div id="root-module">
						<Outlet />
					</div>
				</Box>
				<MainDrawer />
			</Container>
			<Container>
				<Footer />
			</Container>
		</React.Fragment>
	);
}

export default Root;
