
interface IConfigObject {
	SENTRY_DSN: { 
		[index: string]: string
	}
}
/**
 * AT2X Application Configuration Object
 * 
 * | property | description |
 * | ----- | ----- |
 * | SENTRY_DSN | Sentry DSN for each deployment stage |
 */
const config: IConfigObject = {
	SENTRY_DSN: {
		"dev": "https://388fe237b4a646bd8d52cfa1c2bd1d86@o1103530.ingest.sentry.io/6130185",
		"test": "https://1133842c36e148489f8a804102b89cba@o1103530.ingest.sentry.io/4503976357396480",
		"staging": "https://41c99722424b4fe799e3ef2cac17c0f0@o1103530.ingest.sentry.io/4503976738684928",
		"prod": "https://1d37bd971e6a4437baab094b6f939821@o1103530.ingest.sentry.io/4503976740192256"
	}	
}

export default config;
