import React from "react";
import * as ReactDOM from "react-dom/client";
import { store } from "./app/store";
import { Provider as ReduxProvider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from "react-router-dom";
/** 
 * React Router: Route Elements
 */
import Videos from "./routes/Videos";
import Library from "./routes/Library";
import Learning from "./routes/Learning";
import Commands from "./routes/Commands";
import Root from "./routes/Root";
import Games from "./routes/Games";
import ErrorPage from "./components/Error";
import InteractiveWorksheets from "./routes/InteractiveWorksheets";
/** 
 * Sentry Integration (see https://sentry.io)
 */
import { initSentry } from './lib/errorLib';
import ErrorBoundary from "./containers/ErrorBoundary";

initSentry();

/** 
 * Routing and Redux integration
 * https://www.npmjs.com/package/@lagunovsky/redux-react-router
 * redux-react-router may not be compatible with react-router v6.4 as it looks like CreateBrowserRouter 
 * uses the DOM History API as opposed to the history package from Remix themselves.  Can't track it down 
 * exactly but see https://reactrouter.com/en/6.4.0-pre.14/routers/history-router for indications and 
 * the history-router is gone from 6.4.1
 * 
 * https://github.com/salvoravida/redux-first-history also may provide some light on how to manage state and browsing history
 * 
 * https://blog.logrocket.com/react-router-with-redux-navigation-state/
 */
const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<Root />} errorElement={<ErrorPage />} >
			<Route path="games" element={<Games />} />
			<Route path="iw" element={<InteractiveWorksheets />} />
			<Route path="videos" element={<Videos />} />

			<Route path="learning" element={<Learning />} />
			<Route path="library" element={<Library />} />

			<Route path="cli" element={<Commands />} />
		</Route>
	)
);

console.log("Env", process.env);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<ReduxProvider store={store}>
				<ThemeProvider theme={theme}>
					<CssBaseline enableColorScheme />
					<RouterProvider
						router={router}
						// fallbackElement={<LoadingIndicator />}
					/>
				</ThemeProvider>
			</ReduxProvider>
		</ErrorBoundary>
	</React.StrictMode>
);
