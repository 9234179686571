import { useRouteError } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { logError } from "../lib/errorLib";
/**
 * #### React-Router Error Page
 * 
 * TODO: Replace this placeholder with properly implemented error page
 */
const ErrorPage = () => {
	const error: any = useRouteError();
	/**
	 * Log routing errors to Sentry as HTML 404: Page not found.
	 */
	logError(new Error("404: Page Not Found"), {
		name: "Error.tsx: Routing Error",
		message: "Route not found",
		componentStack: error.toString()
	});

	return (
		<Container maxWidth="lg">
			<Box
				display="flex" 
				alignItems="center"
				justifyContent="center"
			>
				<Typography variant="h4" component="h2" gutterBottom>
					Opps!
				</Typography>
			</Box>
			<Box
				display="flex" 
				alignItems="center"
				justifyContent="center"
			>	
				<p>Sorry, an unexpected error has occurred.</p>
			</Box>
			<Box
				display="flex" 
				alignItems="center"
				justifyContent="center"
			>				
				<p>
					<i>{error.statusText || error.message}</i>
				</p>
			</Box>
		</Container>	
	);
}

export default ErrorPage;
