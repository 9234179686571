import React, { ErrorInfo } from "react";
import { logError, IErrorInfo } from "../lib/errorLib";
import ErrorPage from "../components/Error"
/**
 * #### Error Boundary HOC (High Order Component)
 * 
 * Must be a class component because the lifecycle events
 * + getDerviedStateFromError
 * + componentDidCatch
 * do not have React hooks currently.
 * 
 * **Error**:
 * + log the error to Sentry
 * + render the <Error /> component
 * 
 * **No error**:
 * + render the remainder of the children.
 *
 * @class ErrorBoundary
 */
class ErrorBoundary extends React.Component<{children: React.ReactNode}, {}> {
	state = { hasError: false };

	static getDerivedStateFromError(error: Error) {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		logError(error, errorInfo as IErrorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <ErrorPage />
		} else {
			return this.props.children;
		}
	}
}

export default ErrorBoundary;
